import {
  mobileModel,
  mobileVendor,
  osName,
  osVersion
} from 'react-device-detect';

const MOBILE = {
  DIVICE_OS_NAME: osName,
  DIVICE_OS_VERSION: osVersion,
  VENDOR: mobileVendor,
  MODEL: mobileModel,
  DIVICE_WIDTH: window.screen.width * window.devicePixelRatio,
  DIVICE_HEIGHT: window.screen.height * window.devicePixelRatio
};

export default MOBILE;

import axios from 'axios';

import { APP_KEY, BASE_URL_API, MALL_ID } from '../constants/request';

export default axios.create({
  baseURL: BASE_URL_API,
  headers: {
    'app-key': APP_KEY,
    'a-mall-id': MALL_ID
  }
});

import { multiMalls } from '../config/multiMalls';
import { FAVICON_ID, LOCAL_STORAGE_KEY_MALL } from '../constants/mall';
import { setupMap } from '../helpers/map-helper';
import localStorage from '../services/localStorage';

const mallExist = mallId => multiMalls[mallId?.toLowerCase()];

const loadMapConfiguration = async mallId => {
  const configMall = mallExist(mallId);

  if (!configMall) {
    return false;
  }

  const informationJibestream = await setupMap(configMall);

  localStorage.setValue(LOCAL_STORAGE_KEY_MALL, mallId);

  return { configMall, informationJibestream };
};

const getConfigMall = () => {
  const mallId = localStorage.getValue(LOCAL_STORAGE_KEY_MALL);

  return mallExist(mallId);
};

const changeFavicon = href => {
  const favicon = document.getElementById(FAVICON_ID);

  favicon.href = href;
};

export { getConfigMall, loadMapConfiguration, changeFavicon };

import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';

import Main from './components/Main/view';
import { theme } from './theme/theme';
import configureStore, { history } from './rootStore';
import './styles/app.scss';
import './styles/fonts.css';
import { JibestreamContextProvider } from './context/JibestreamContext';

const store = configureStore();

const App = () => {
  const [jibestreamContext, setJibestreamContext] = useState({
    configMap: null,
    configDeviceKit: null
  });

  return (
    <Provider store={store}>
      <Router history={history}>
        <MuiThemeProvider theme={theme}>
          <JibestreamContextProvider
            value={{ jibestreamContext, setJibestreamContext }}
          >
            <Main />
          </JibestreamContextProvider>
        </MuiThemeProvider>
      </Router>
    </Provider>
  );
};

export default App;

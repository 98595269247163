import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../i18n';
import CentreMapParking from '../../components/Mapa/centre-map-parking';
import './ResultadoParking.scss';
import { sendEventLoadingMap } from '../../utils/googleAnalytics';

class ResultadoMapaParking extends React.Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    const { params } = match;
    const { origin } = params;

    this.isBuiltYourRoute = !!origin;
  }

  static defaultProps = {
    params: null
  };

  static propTypes = {
    match: PropTypes.any.isRequired,
    params: PropTypes.any
  };

  render() {
    const { match } = this.props;
    const { params } = match;
    const { mall, originMall, destinationParking, area, language } = params;

    const showPath = originMall !== undefined;

    i18n.changeLanguage(language);

    return (
      <div className=''>
        <div className='seccion-mapa'>
          <CentreMapParking
            showPath={showPath}
            mall={mall}
            originMall={originMall}
            destinationParking={destinationParking}
            area={area}
            getJbInformation={sendEventLoadingMap(mall)}
          />
        </div>
      </div>
    );
  }
}

export default ResultadoMapaParking;

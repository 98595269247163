import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

import ListItem from '../ListItem';
import search from '../../assets/images/landing/ic-search.svg';
import { getStoreList } from '../../services/landing';
import { COLOR_SPINNER } from '../Mapa/styles';
import { getDateString } from '../../utils/date';
import { LIMIT_PAGE_STORES } from '../../constants/request';

import style from './style.module.css';

List.propTypes = {
  handleClickStore: PropTypes.func.isRequired,
  mallData: PropTypes.any.isRequired
};

function List({ handleClickStore, mallData }) {
  const [listStores, setListStores] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const refSearch = useRef('');
  const mall = mallData?.mallCode;

  const getStores = async ({ name = '', skip, list }) => {
    if (notFound) {
      setNotFound(false);
    }

    const { data } = await getStoreList(name, skip, mall);

    if (data.length === 0) {
      ReactGA.event({
        category: 'Landing - Búsqueda tienda',
        action: 'Búsqueda sin resultado',
        label: `Busqueda: ${name}`
      });
      ReactGA4.event({
        category: 'Landing - Búsqueda tienda',
        action: 'Búsqueda sin resultado',
        label: `Busqueda: ${name}`
      });
      setNotFound(true);
    }

    setHasMore(data.length >= LIMIT_PAGE_STORES);
    setListStores([...list, ...data]);
  };

  const handleInputChange = () => {
    if (refSearch.current.value === '') {
      setHasMore(true);
      setListStores([]);
      getStores({
        skip: 0,
        list: []
      });
    }
  };

  const handleClickSearch = () => {
    setHasMore(true);
    setListStores([]);

    ReactGA.event({
      category: 'Landing - Búsqueda tienda',
      action: 'Click botón buscar',
      label: `Busqueda: ${refSearch.current.value}`
    });
    ReactGA4.event({
      category: 'Landing - Búsqueda tienda',
      action: 'Click botón buscar',
      label: `Busqueda: ${refSearch.current.value}`
    });

    getStores({
      name: refSearch.current.value,
      skip: 0,
      list: []
    });
  };

  const handleKeyDown = ({ key }) => {
    if (key === 'Enter') {
      setHasMore(true);
      setListStores([]);

      ReactGA.event({
        category: 'Landing - Búsqueda tienda',
        action: 'Botón Enter',
        label: `Busqueda: ${refSearch.current.value}`
      });
      ReactGA4.event({
        category: 'Landing - Búsqueda tienda',
        action: 'Botón Enter',
        label: `Busqueda: ${refSearch.current.value}`
      });

      getStores({
        name: refSearch.current.value,
        skip: 0,
        list: []
      });
    }
  };

  useEffect(() => {
    getStores({
      skip: 0,
      list: []
    });
  }, []);

  return (
    <div className={style.contentSearch}>
      <h1 className={style.title}>Encuentra tu tienda</h1>
      <p className={style.date}>{getDateString()}</p>
      <p>Selecciona la tienda a la que quieres ir</p>
      <div className={style.contentInput}>
        <input
          type='text'
          placeholder='Tienda'
          className={style.inputSearch}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          ref={refSearch}
        />
        <button
          type='button'
          className={style.buttonSearch}
          onClick={handleClickSearch}
        >
          <img src={search} alt='' className={style.iconSearch} />
        </button>
      </div>
      {notFound && refSearch.current.value !== '' && (
        <div>
          <p className={`${style.bigMessage} ${style.bold}`}>
            No se encontraron resultados
          </p>
          <p>
            Tu búsqueda para
            <span className={style.bold}>
              {' '}
              &quot;{refSearch.current.value}&quot;{' '}
            </span>{' '}
            no arrojó ningún resultado.
          </p>
        </div>
      )}

      <InfiniteScroll
        dataLength={listStores.length}
        next={() =>
          getStores({
            name: refSearch.current.value,
            skip: listStores.length,
            list: listStores
          })
        }
        hasMore={hasMore}
        loader={
          <div className={style.contentProgress}>
            <CircularProgress style={COLOR_SPINNER} />
          </div>
        }
        endMessage={<div />}
      >
        <div className={style.contentList}>
          {listStores.map(store => (
            <ListItem
              key={store.id}
              store={store}
              handleClickStore={handleClickStore}
            />
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
}

export default List;

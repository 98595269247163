import faviconParauco from '../assets/favicons/parauco.ico';

const multiMalls = {
  pak: {
    customerId: parseInt(process.env.REACT_APP_JIBESTREAM_CUSTOMER_ID_PAK, 10),
    clientId: process.env.REACT_APP_JIBESTREAM_CLIENT_ID_PAK,
    secret: process.env.REACT_APP_JIBESTREAM_SECRET_PAK,
    venueId: process.env.REACT_APP_JIBESTREAM_VENUE_ID_PAK,
    sucId: ['S0210', 'S0300', 'S0400'],
    favicon: faviconParauco,
    buttonsFloors: [
      {
        color: 'default',
        variant: 'contained',
        textButton: 'Piso 3',
        floor: 4580
      },
      {
        color: 'default',
        variant: 'contained',
        textButton: 'Piso 2',
        floor: 4578
      },
      {
        color: 'default',
        variant: 'contained',
        textButton: 'Piso 1',
        floor: 4575
      }
    ]
  },
  ach: {
    customerId: parseInt(process.env.REACT_APP_JIBESTREAM_CUSTOMER_ID_PAK, 10),
    clientId: process.env.REACT_APP_JIBESTREAM_CLIENT_ID_ACH,
    secret: process.env.REACT_APP_JIBESTREAM_SECRET_ACH,
    venueId: process.env.REACT_APP_JIBESTREAM_VENUE_ID_ACH,
    sucId: ['SC', 'SA1', 'S200', 'SB'],
    favicon: faviconParauco,
    buttonsFloors: [
      {
        color: 'default',
        variant: 'contained',
        textButton: 'Mezzanine',
        floor: 5283
      },
      {
        color: 'default',
        variant: 'contained',
        textButton: 'Piso 6',
        floor: 5282
      },
      {
        color: 'default',
        variant: 'contained',
        textButton: 'Piso 5',
        floor: 5281
      },
      {
        color: 'default',
        variant: 'contained',
        textButton: 'Piso 4',
        floor: 5280
      },
      {
        color: 'default',
        variant: 'contained',
        textButton: 'Piso 3',
        floor: 5279
      },
      {
        color: 'default',
        variant: 'contained',
        textButton: 'Piso 2',
        floor: 5278
      },
      {
        color: 'default',
        variant: 'contained',
        textButton: 'Piso 1',
        floor: 5276
      },
      {
        color: 'default',
        variant: 'contained',
        textButton: 'Ground Floor',
        floor: 5277
      }
    ]
  },
  bom: {
    customerId: parseInt(process.env.REACT_APP_JIBESTREAM_CUSTOMER_ID_PAK, 10),
    clientId: process.env.REACT_APP_JIBESTREAM_CLIENT_ID_ACH,
    secret: process.env.REACT_APP_JIBESTREAM_SECRET_ACH,
    venueId: process.env.REACT_APP_JIBESTREAM_VENUE_ID_BOM,
    sucId: ['S34', 'S83', 'BODEGA M'],
    favicon: faviconParauco,
    buttonsFloors: [
      {
        color: 'default',
        variant: 'contained',
        textButton: 'Piso 1',
        floor: 5272
      }
    ]
  },
  mam: {
    customerId: parseInt(process.env.REACT_APP_JIBESTREAM_CUSTOMER_ID_PAK, 10),
    clientId: process.env.REACT_APP_JIBESTREAM_CLIENT_ID_ACH,
    secret: process.env.REACT_APP_JIBESTREAM_SECRET_ACH,
    venueId: process.env.REACT_APP_JIBESTREAM_VENUE_ID_MAM,
    sucId: ['S145', 'S555', 's500', 'S303', 'S302', 'S301'],
    favicon: faviconParauco,
    buttonsFloors: [
      {
        color: 'default',
        variant: 'contained',
        textButton: 'Piso 2',
        floor: 5106
      },
      {
        color: 'default',
        variant: 'contained',
        textButton: 'Piso 1',
        floor: 5105
      }
    ]
  },
  mae: {
    customerId: parseInt(process.env.REACT_APP_JIBESTREAM_CUSTOMER_ID_PAK, 10),
    clientId: process.env.REACT_APP_JIBESTREAM_CLIENT_ID_ACH,
    secret: process.env.REACT_APP_JIBESTREAM_SECRET_ACH,
    venueId: process.env.REACT_APP_JIBESTREAM_VENUE_ID_MAE,
    sucId: ['SPARIS-1', 'SSOD-1', 'S152'],
    favicon: faviconParauco,
    buttonsFloors: [
      {
        color: 'default',
        variant: 'contained',
        textButton: 'Piso 2',
        floor: 5473
      },
      {
        color: 'default',
        variant: 'contained',
        textButton: 'Piso 1',
        floor: 5451
      }
    ]
  },

  ppak: {
    customerId: parseInt(process.env.REACT_APP_JIBESTREAM_CUSTOMER_ID_PAK, 10),
    clientId: process.env.REACT_APP_JIBESTREAM_CLIENT_ID_PAK,
    secret: process.env.REACT_APP_JIBESTREAM_SECRET_PAK,
    venueId: 1093,
    sucId: ['S0210', 'S0300', 'S0400'],
    favicon: faviconParauco,
    buttonsFloors: [
      {
        color: 'default',
        variant: 'contained',
        textButton: 'Piso 6',
        floor: 3360
      },
      {
        color: 'default',
        variant: 'contained',
        textButton: 'Piso 5',
        floor: 3359
      },
      {
        color: 'default',
        variant: 'contained',
        textButton: 'Piso 4',
        floor: 3358
      },
      {
        color: 'default',
        variant: 'contained',
        textButton: 'Piso 3',
        floor: 3357
      },
      {
        color: 'default',
        variant: 'contained',
        textButton: 'Piso 2',
        floor: 3355
      },
      {
        color: 'default',
        variant: 'contained',
        textButton: 'Piso 1',
        floor: 3354
      },
      {
        color: 'default',
        variant: 'contained',
        textButton: 'Piso -1',
        floor: 3361
      },
      {
        color: 'default',
        variant: 'contained',
        textButton: 'Piso -2',
        floor: 3362
      },
      {
        color: 'default',
        variant: 'contained',
        textButton: 'Piso -3',
        floor: 3363
      },
      {
        color: 'default',
        variant: 'contained',
        textButton: 'Piso -4',
        floor: 3364
      },
      {
        color: 'default',
        variant: 'contained',
        textButton: 'Piso -5',
        floor: 3365
      },
      {
        color: 'default',
        variant: 'contained',
        textButton: 'Piso -6',
        floor: 3366
      }
    ]
  }
};

export { multiMalls };

/* eslint-disable no-console */
import jmap from 'jmap.js';

import { HOST_JIBESTREAM } from '../constants/mall';

export const setupMap = ({ clientId, secret, customerId, venueId, sucId }) => {
  const core = new jmap.core.JCore({
    HOST_JIBESTREAM,
    auth: new jmap.core.Auth(clientId, secret, customerId),
    customerId,
    showAllAmenities: true,
    showAllPathTypes: true,
    rotation: false,
    applyDisplayMode: true
  });

  return new Promise((resolve, reject) => {
    core.populateVenueByVenueId(venueId, (error, activeVenue) => {
      if (error) {
        console.log('error populating active venue', error);

        return reject(error);
      }

      const buildingId = activeVenue.buildings.getDefault().id;

      return core.populateBuildingInVenue(activeVenue, buildingId, err2 => {
        if (err2) {
          console.log('error populating buidling in venue', err2);

          return reject(err2);
        }

        return resolve({
          activeVenue,
          core,
          destinations: activeVenue.destinations.getAll(),
          highlightedDestinations: activeVenue.destinations.filter(
            ({ externalId }) => sucId.includes(externalId)
          )
        });
      });
    });
  });
};

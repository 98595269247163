import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../i18n';
import CentreMap from '../../components/Mapa/centre-map';
import './Resultado.scss';
import { sendEventLoadingMap } from '../../utils/googleAnalytics';

class ResultadoMapa extends React.Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line react/destructuring-assignment
    this.isBuiltYourRoute = !!this.props.match.params.origin;
  }

  static defaultProps = {
    params: null
  };

  static propTypes = {
    match: PropTypes.any.isRequired,
    params: PropTypes.any
  };

  render() {
    const { match } = this.props;
    const { params } = match;
    const {
      mall,
      origin,
      destination,
      amenity,
      type,
      noShowPath,
      noDestination,
      destinationFloor,
      language
    } = params;

    i18n.changeLanguage(language);

    let showPath = origin !== undefined;

    if (noShowPath) {
      showPath = false;
    }

    const amenityFinal = destinationFloor !== undefined ? type : amenity;

    return (
      <div className=''>
        <div className='seccion-mapa'>
          <CentreMap
            noDestination={noDestination}
            showPath={showPath}
            mall={mall}
            origin={origin}
            destination={destination}
            amenity={amenityFinal}
            type={type}
            destinationFloor={destinationFloor}
            getJbInformation={sendEventLoadingMap(mall)}
          />
        </div>
      </div>
    );
  }
}

export default ResultadoMapa;

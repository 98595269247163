import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Trans } from 'react-i18next';

function ButtonFloor({
  items,
  onHandleChangeFloor,
  onHandleIsLevelActive,
  onHandleIsButtonDisabled
}) {
  let floorNumber = '';

  if (items.textButton) {
    floorNumber = items.textButton.split(' ');
  }

  return (
    <Button
      disabled={onHandleIsButtonDisabled(items.floor)}
      className={`small ${onHandleIsLevelActive(items.floor)}`}
      onClick={onHandleChangeFloor(items.floor)}
      color={items.color}
      variant={items.variant}
    >
      <Trans i18nKey='FLOORS_NAME' /> {floorNumber[1]}
    </Button>
  );
}

ButtonFloor.propTypes = {
  items: PropTypes.object.isRequired,
  onHandleIsButtonDisabled: PropTypes.func.isRequired,
  onHandleIsLevelActive: PropTypes.func.isRequired,
  onHandleChangeFloor: PropTypes.func.isRequired
};

export default ButtonFloor;

export const LEFT = 'Left';
export const RIGHT = 'Right';
export const TURN = 'Turn';
export const STATUS_FLOOR = 'active';
export const ESCALATOR = 'Escalator';
export const STAIRS = 'Stairs';
export const ELEVATOR = 'Elevator';
export const COLOR_STORE_DESTINATION = '#03A9F4';
export const SCALE = 3;

export const DURATION_ANIMATION_TRACE_PATH = 3;
export const TIME_TO_SHOW_THE_MODAL = 6000;
export const TIME_TO_CLOSE_MODAL = 3000;
export const TIME_TO_SHOW_NEXT_FLOOR = 3500;

export const bla = {
  modal: {
    width: '80%',
    margin: 'auto',
    marginTop: '150px',
    borderRadius: '50px',
    textAlign: 'center',
    padding: '7%',
    fontSize: '3.3vh',
    backgroundColor: '#FFFFFF',
    height: '30%',
    display: 'flex',
    flexDirection: 'column',
    border: 'solid transparent'
  }
};

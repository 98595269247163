import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Backdrop } from '@material-ui/core';

import CentreMap from '../Mapa/centre-map';
import { sendEventLoadingMap } from '../../utils/googleAnalytics';

import style from './style.module.css';

function ModalMap({ dataStore, origin, openModal, handleCloseModal }) {
  const { suc, mall } = dataStore || { suc: null, mall: null };
  const { stringId } = mall || {};

  return (
    <Modal
      open={openModal}
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      BackdropComponent={Backdrop}
      closeAfterTransition
      onClose={handleCloseModal}
    >
      <div className={style.contentMapa}>
        <button
          type='button'
          onClick={handleCloseModal}
          className={style.closeModal}
          id='closeRouteModal'
        >
          X
        </button>
        {dataStore && openModal && (
          <CentreMap
            showPath
            mall={stringId}
            origin={origin}
            destination={suc}
            getJbInformation={sendEventLoadingMap(stringId)}
            handleCloseModal={handleCloseModal}
          />
        )}
      </div>
    </Modal>
  );
}

ModalMap.propTypes = {
  dataStore: PropTypes.any,
  origin: PropTypes.string.isRequired,
  openModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired
};

ModalMap.defaultProps = {
  dataStore: null
};

export default ModalMap;

export const BASE_URL_API = 'https://api.parauco.com/';
export const APP_KEY = '88ed5442771c4e39a74279f0f227422a';
export const MALL_ID = 1;
export const URL_ENDPOINT_STORES = 'pois/allCustomer';
export const DATA_STORES = [
  'logo',
  'poiMallZone',
  'travelerDiscounts',
  'deals',
  'poisPhunware',
  'poiState'
];
export const POI_TYPE_ID_STORES = [2, 6, 32];
export const LIMIT_PAGE_STORES = 25;
export const POI_STATE_ID_STORES = [1, 3, 6];
export const ORDER_BY_NAME_ASC = 'name ASC';

import React from 'react';
import Lottie from 'react-lottie';
import { Trans } from 'react-i18next';

import {
  escalatorDownLottie,
  escalatorUPLottie,
  elevatorDownLottie,
  elevatorUpLottie,
  stairsDownLottie,
  stairsUpLottie
} from './constantsImageLottie';
import styles from './style';
import './generalMapStyle.scss';

const MAP_MOVER_ELEVATOR_ID = 2;
const MAP_MOVER_ESCALATOR_ID = 3;
const MAP_MOVER_STAIRS_ID = 4;

export const getImgModalUp = (moverId, messageModal) => {
  if (moverId === MAP_MOVER_ESCALATOR_ID) {
    return messageModal ? (
      <div className='modalAccesibilidad' style={styles.modal}>
        <Lottie options={escalatorUPLottie} />
        <span style={styles.modalTitle}>
          <Trans i18nKey='UP_STAIRS_MECHANICAL' />
        </span>
      </div>
    ) : (
      <div className='modalAccesibilidad' style={styles.modal}>
        <Lottie options={escalatorDownLottie} />
        <span style={styles.modalTitle}>
          <Trans i18nKey='DOWN_STAIRS_MECHANICAL' />
        </span>
      </div>
    );
  }

  if (moverId === MAP_MOVER_ELEVATOR_ID) {
    return messageModal ? (
      <div className='modalAccesibilidad' style={styles.modal}>
        <Lottie options={elevatorUpLottie} />
        <span style={styles.modalTitle}>
          <Trans i18nKey='UP_ELEVATOR' />
        </span>
      </div>
    ) : (
      <div className='modalAccesibilidad' style={styles.modal}>
        <Lottie options={elevatorDownLottie} />
        <span style={styles.modalTitle}>
          <Trans i18nKey='DOWN_ELEVATOR' />
        </span>
      </div>
    );
  }

  if (moverId === MAP_MOVER_STAIRS_ID) {
    return messageModal ? (
      <div className='modalAccesibilidad' style={styles.modal}>
        <Lottie options={stairsUpLottie} />
        <span style={styles.modalTitle}>
          <Trans i18nKey='UP_STAIRS' />
        </span>
      </div>
    ) : (
      <div className='modalAccesibilidad' style={styles.modal}>
        <Lottie options={stairsDownLottie} />
        <span style={styles.modalTitle}>
          <Trans i18nKey='DOWN_STAIRS' />
        </span>
      </div>
    );
  }

  return <span>No se tiene mensaje</span>;
};

import React from 'react';
import PropTypes from 'prop-types';

import style from './style.module.css';

List.propTypes = {
  store: PropTypes.any.isRequired,
  handleClickStore: PropTypes.func.isRequired
};

function List({ store, handleClickStore }) {
  const { name, logo } = store;

  return (
    <div className={style.item} onClick={() => handleClickStore(store)}>
      <div className={style.contentLogo}>
        <img src={logo ? logo.url : ''} alt='' className={style.logo} />
      </div>
      <div className={style.name}>{name}</div>
    </div>
  );
}

export default List;

import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

import { getDeviceFeatures } from '../config/kiosk';
import MOBILE from '../constants/diviceInformationConstants';
import {
  ACTION_TYPE,
  CATEGORY_TYPE,
  TIME_TO_SEND_THE_LOAD_ERROR
} from '../constants/googleAnalyticsConstants';

const sendEventStepOne = (mall, seconds, qr) => {
  ReactGA.event({
    category: CATEGORY_TYPE.STEP_1_JB_REQUEST,
    action: `${ACTION_TYPE.INIT_PATH_JB} ${qr};`,
    label: String('Mall = ')
      .concat(mall)
      .concat('; ')
      .concat(getDeviceFeatures(MOBILE, seconds))
  });
  ReactGA4.event({
    category: CATEGORY_TYPE.STEP_1_JB_REQUEST,
    action: `${ACTION_TYPE.INIT_PATH_JB} ${qr};`,
    label: String('Mall = ')
      .concat(mall)
      .concat('; ')
      .concat(getDeviceFeatures(MOBILE, seconds))
  });
};

const sendEventStepTwo = (mall, seconds, qr) => {
  ReactGA.event({
    category: CATEGORY_TYPE.STEP_2_END_OF_ROUTE,
    action: `${ACTION_TYPE.FULL_PATH_JB} ${qr};`,
    label: String('Mall = ')
      .concat(mall)
      .concat('; ')
      .concat(getDeviceFeatures(MOBILE, seconds))
  });
  ReactGA4.event({
    category: CATEGORY_TYPE.STEP_2_END_OF_ROUTE,
    action: `${ACTION_TYPE.FULL_PATH_JB} ${qr};`,
    label: String('Mall = ')
      .concat(mall)
      .concat('; ')
      .concat(getDeviceFeatures(MOBILE, seconds))
  });
};

const sendEventLoadingMap = mall =>
  setTimeout(() => {
    const qr = window?.location?.pathname?.split('/')[3];

    ReactGA.event({
      category: CATEGORY_TYPE.TAG_JB_INFORMATION,
      action: `${ACTION_TYPE.TAG_ACCESSING_JB} ${qr};`,
      label: `Mall = ${mall};
           SO = ${MOBILE.DIVICE_OS_NAME};
           Versión = ${MOBILE.DIVICE_OS_VERSION};
           Marca = ${MOBILE.VENDOR};
           Modelo = ${MOBILE.MODEL};
           Divice = ${MOBILE.DIVICE_WIDTH}-${MOBILE.DIVICE_HEIGHT};`
    });
    ReactGA4.event({
      category: CATEGORY_TYPE.TAG_JB_INFORMATION,
      action: `${ACTION_TYPE.TAG_ACCESSING_JB} ${qr};`,
      label: `Mall = ${mall};
           SO = ${MOBILE.DIVICE_OS_NAME};
           Versión = ${MOBILE.DIVICE_OS_VERSION};
           Marca = ${MOBILE.VENDOR};
           Modelo = ${MOBILE.MODEL};
           Divice = ${MOBILE.DIVICE_WIDTH}-${MOBILE.DIVICE_HEIGHT};`
    });
  }, TIME_TO_SEND_THE_LOAD_ERROR);

const sendEventGeneratedPath = (mall, from, to) => {
  ReactGA.event({
    category: CATEGORY_TYPE.GENERATED_PATH,
    action: `${ACTION_TYPE.DRAW_PATH}`,
    label: `Mall = ${mall};
            Ruta desde = ${from} hasta = ${to};`
  });
  ReactGA4.event({
    category: CATEGORY_TYPE.GENERATED_PATH,
    action: `${ACTION_TYPE.DRAW_PATH}`,
    label: `Mall = ${mall};
            Ruta desde = ${from} hasta = ${to};`
  });
};

const sendEventPathRepeat = (mall, origin, destination) => {
  ReactGA.event({
    category: CATEGORY_TYPE.PATH_REPEAT,
    action: `${ACTION_TYPE.PATH_REPEAT_BUTTON}`,
    label: `Mall = ${mall};
           Origen = ${origin};
           Destino = ${destination};`
  });
  ReactGA4.event({
    category: CATEGORY_TYPE.PATH_REPEAT,
    action: `${ACTION_TYPE.PATH_REPEAT_BUTTON}`,
    label: `Mall = ${mall};
           Origen = ${origin};
           Destino = ${destination};`
  });
};

const sendEventMapError = (mall, origin, destination, errorMsg) => {
  ReactGA.event({
    category: CATEGORY_TYPE.ERROR_MAP,
    action: `${ACTION_TYPE.CATCH_ERROR}`,
    label: `Mall = ${mall};
           Origen = ${origin};
           Destino = ${destination};
           Error: ${errorMsg}`
  });
  ReactGA4.event({
    category: CATEGORY_TYPE.ERROR_MAP,
    action: `${ACTION_TYPE.CATCH_ERROR}`,
    label: `Mall = ${mall};
           Origen = ${origin};
           Destino = ${destination};
           Error: ${errorMsg}`
  });
};

export {
  sendEventStepOne,
  sendEventStepTwo,
  sendEventLoadingMap,
  sendEventGeneratedPath,
  sendEventPathRepeat,
  sendEventMapError
};

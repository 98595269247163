import React from 'react';
import PropTypes from 'prop-types';

import logo from '../../assets/images/landing/logo.png';
import logoPAK from '../../assets/images/landing/logos/Logo_PAK.png';
import logoMAM from '../../assets/images/landing/logos/Logo_MAM.svg';
import logoBOM from '../../assets/images/landing/logos/Logo_BOM.svg';
import logoACH from '../../assets/images/landing/logos/Logo_ACH.svg';
import logoMAE from '../../assets/images/landing/logos/Logo_MAE.svg';
import info from '../../assets/images/landing/info.svg';
import close from '../../assets/images/landing/ic-close.svg';

import style from './style.module.css';
import { URL_STORES } from './constants';

Header.propTypes = {
  mallData: PropTypes.any.isRequired
};

function Header({ mallData }) {
  const [showInfo, setShowInfo] = React.useState(false);

  let logoMall;

  switch (mallData?.mallCode) {
    case 'PAK':
      logoMall = logoPAK;
      break;
    case 'MAM':
      logoMall = logoMAM;
      break;
    case 'BOM':
      logoMall = logoBOM;
      break;
    case 'ACH':
      logoMall = logoACH;
      break;
    case 'MAE':
      logoMall = logoMAE;
      break;
    default:
      logoMall = logo;
      break;
  }

  return (
    <header className={style.header}>
      <div className={style.contentHeader}>
        <div>
          <img
            id='logoHeader'
            src={logoMall}
            alt='logo'
            className={style.logo}
          />
        </div>
        {mallData && (
          <a
            href={mallData.webUrl}
            className={style.linkHeader}
            target='_blank'
            rel='noopener noreferrer'
          >
            Ir al sitio web
          </a>
        )}
      </div>
      {showInfo && (
        <div className={style.contentInfo}>
          <p className={style.info}>
            <img src={info} alt='logo' className={style.imgInfo} />
            Conoce las tiendas abiertas y sus horarios
            <a
              href={URL_STORES}
              className={style.linkBlue}
              target='_blank'
              rel='noopener noreferrer'
            >
              &nbsp;aquí
            </a>
            <button
              type='button'
              onClick={() => setShowInfo(false)}
              className={style.closeInfo}
            >
              <img src={close} alt='' />
            </button>
          </p>
        </div>
      )}
    </header>
  );
}

export default Header;

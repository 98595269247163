export const calculateSeconds = (initialDate, finalDate) => {
  const dif = initialDate.getTime() - finalDate.getTime();

  const seconds = dif / 1000;

  return Math.abs(seconds);
};

export const getDateString = () => {
  const date = new Date();

  return date.toLocaleDateString('es-ES', {
    day: 'numeric',
    month: 'long'
  });
};

export const CATEGORY_TYPE = {
  STEP_1_JB_REQUEST: 'ATR - Paso 1 - Solicitud a JB',
  TAG_JB_INFORMATION: 'ATR - Demora en Obtención de información JB',
  STEP_2_END_OF_ROUTE: 'ATR - Paso 2- Final de Ruta',
  GENERATED_PATH: 'Mapa - Rutas generadas',
  PATH_REPEAT: 'Mapa - Repetir Ruta',
  ERROR_MAP: 'Mapa - Error de solicitud',
  MAP_LOAD: 'Mapa - Despliegue'
};

export const ACTION_TYPE = {
  INIT_PATH_JB: 'Inicio de Ruta - QR =',
  TAG_ACCESSING_JB: 'Más de 5 seg en obtener información de JB - QR =',
  FULL_PATH_JB: 'Ruta completa - QR = ',
  DRAW_PATH: 'Desplegar ruta',
  PATH_REPEAT_BUTTON: 'Click Botón Repetir Ruta',
  CATCH_ERROR: 'Captura error de solictud a JB',
  MAP_LOAD_REQUEST: 'Solicitud de Mapa',
  MAP_LOAD_RESPONSE: 'Respuesta correcta de mapa'
};

export const TIME_TO_SEND_THE_LOAD_ERROR = 5000; // VALUE IN MILLISECONDS

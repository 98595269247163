import jmap from 'jmap.js';
import DeviceKit from '@jibestream-dev/jmap-device-kit';

import { COLOR_STORE_DESTINATION, SCALE } from '../components/Mapa/constants';
import {
  STYLE_HIGH_LIGHT,
  STYLE_ICON_END,
  STYLE_ICON_START,
  STYLE_PATH
} from '../components/Mapa/styles';
import { CONTAINER_MAP } from '../constants/mall';

const highlight = (destinations, jmapController) => {
  const highlightStyle = new jmap.Style(STYLE_HIGH_LIGHT);

  destinations.forEach(d => {
    const units = jmapController.getUnitsFromDestination(d);

    jmapController.styleShapes(units, highlightStyle);
  });
};

const cleanString = (string, escape = false) => {
  const newString = string ? string.toString().toLowerCase() : '';

  return escape ? newString.replace(/[ ,-]/g, '') : newString;
};

const getStore = (stores, find) =>
  stores.find(store => {
    if (store.externalId !== null && find !== null) {
      return (
        cleanString(store.externalId, true) === cleanString(find, true) ||
        cleanString(store.name) === cleanString(find)
      );
    }

    return false;
  });

const getWaypointBySuc = (suc, amenities, maps) => {
  for (let index = 0; index < amenities.length; index++) {
    const amenity = amenities[index];
    const waypoints = maps.getWaypointsByAmenity(amenity);

    if (waypoints.length > 0) {
      const waypointResult = waypoints.find(
        waypoint =>
          waypoint.externalId &&
          String(waypoint.externalId).toLowerCase() ===
            String(suc).toLowerCase()
      );

      if (waypointResult) {
        return waypointResult;
      }
    }
  }

  return false;
};

const getStoreFromAmenitie = (destination, venue) => {
  const { maps } = venue;

  return getWaypointBySuc(destination, venue.amenities.getAll(), maps);
};

const zoom = (bounds, duration, zoomValue, jmapController) => {
  jmapController.fitBoundsInView(
    bounds,
    new jmap.Animation({ duration }),
    zoomValue
  );
};

const zoomFromWaypoint = (jmapController, waypoint, duration, zoomScale) => {
  jmapController.setMapTransform(
    new jmap.Transform({
      x: waypoint.coordinates[0],
      y: waypoint.coordinates[1],
      scale: zoomScale,
      animation: new jmap.Animation({ duration })
    })
  );
};

const colorDestinationStore = (jmapController, store) => {
  const units = jmapController.getUnitsFromDestination(store);

  jmapController.styleShapes(
    units,
    new jmap.Style({ fill: COLOR_STORE_DESTINATION })
  );

  return units;
};

const colorDestinationAmenity = (jmapController, waypoint, venue) => {
  const divParent = document.createElement('div');

  divParent.style.marginTop = '-28px';
  divParent.style.marginLeft = '-14px';

  const divChildren = document.createElement('div');

  divChildren.style.backgroundColor = COLOR_STORE_DESTINATION;
  divChildren.classList.add('marker-map');
  divParent.append(divChildren);

  const { mapId } = waypoint;
  const map = venue.maps.getById(mapId);

  jmapController.addComponent(divParent, map, waypoint.coordinates);
};

const highlightAndZoom = (jmapController, store, focusStore, venue) => {
  const units = colorDestinationStore(jmapController, store);

  if (focusStore) {
    const bounds = jmapController.getBoundsFromShapes(units);
    const mapId = units[0].parent.parent.id;
    const map = venue.maps.getById(mapId);

    jmapController.showMap(map);

    zoom(bounds, 1, 50, jmapController);

    return mapId;
  }

  return false;
};

const highlightAndZoomFromAmenity = (
  jmapController,
  store,
  focusStore,
  venue
) => {
  colorDestinationAmenity(jmapController, store, venue);

  if (focusStore) {
    const { mapId } = store;
    const map = venue.maps.getById(mapId);

    jmapController.showMap(map);
    zoomFromWaypoint(jmapController, store, 1, 10);

    return mapId;
  }

  return false;
};

const changeFloor = (mapId, showPath, jmapController, venue) => {
  const map = venue.maps.getById(mapId);
  const path = jmapController.getShapesInLayer('Wayfinding-Path', map);
  const bounds = jmapController.getBoundsFromShapes(path);

  jmapController.showMap(map);

  if (showPath) {
    zoom(bounds, 1, 150, jmapController);
  }

  return mapId;
};

const initControllerMap = (venue, { current }) => {
  let size = {};

  if (current) {
    size = {
      width: current.clientWidth,
      height: current.clientHeight
    };
  }

  const controller = new jmap.JController({
    activeVenue: venue,
    container: `#${CONTAINER_MAP}`, // where the map lives
    forceCanvas: true, // SCALA doesn't support webgl
    ...size
  });

  // this.CONTROL.parseAllMaps();
  controller.showDefaultMap();
  controller.setMapTransform(new jmap.Transform({ scale: SCALE }));

  return controller;
};

const getDeviceByName = (origin, mapDevices) =>
  mapDevices.find(
    d => String(d.externalId).toLowerCase() === String(origin).toLowerCase()
  );

const getWaypointFromDestination = (destination, venue) => {
  let a = venue.maps.getWaypointsByDestination(destination)[0];

  if (!a) {
    a = destination;
  }

  return a;
};

const applyDeviceOrientation = (device, jmapController) => {
  const transform = new jmap.Transform({ rotation: -1 * device.heading });

  jmapController.setMapTransform(transform);
};

const loadDevices = async deviceKit => {
  await new Promise((resolve, reject) => {
    deviceKit.getDevices(async (error, devices) => {
      if (error) {
        reject(error);
      }

      const parsedDevices = devices._items.map(item => item._);

      resolve(parsedDevices);
    });
  });

  return deviceKit.devices.getAll();
};

const initMapDevices = async (jmapController, core, mapDevices, origin) => {
  const deviceKit = new DeviceKit(core, jmapController);
  let devices = mapDevices;

  if (mapDevices.length === 0) {
    devices = await loadDevices(deviceKit);
  }

  const originDevice = getDeviceByName(origin, devices);

  if (originDevice) {
    applyDeviceOrientation(originDevice, jmapController);

    return { deviceKit, devices };
  }

  return null;
};

const prepareMapForRender = (_from, _to, jmapController, accesibleRoute) => {
  let error = '';

  if (!_from) {
    error = `origin not valid ${_from}`;
  }

  if (!_to) {
    error = `destination not valid ${_to}`;
  }

  // Calculate a wayfinding path
  const path = jmapController.wayfindBetweenWaypoints(
    _from,
    _to,
    accesibleRoute
  );

  if (path && path.length > 0) {
    // this.getInstruccions(path);
    // Create a style for the path
    const pathStyle = new jmap.Style(STYLE_PATH);

    // Draw the static path on the map
    jmapController.drawWayfindingPath(path, pathStyle);
    // Create start & end Icon
    const icons = {
      start: new jmapController.jungle.Icon({
        ...STYLE_ICON_START,
        point: _from.coordinates
      }),
      end: new jmapController.jungle.Icon({
        ...STYLE_ICON_END,
        point: _to.coordinates
      })
    };
    // Get the mapView objects to place icons on
    const mapViews = {
      start: jmapController.stage.getMapViewById(path[0].mapId),
      end: jmapController.stage.getMapViewById(path[path.length - 1].mapId)
    };
    // Get the path layers on each map to place the icon
    const pathLayers = {
      start: mapViews.start.guaranteeMapLayer('Wayfinding-Path'),
      end: mapViews.end.guaranteeMapLayer('Wayfinding-Path')
    };

    // Add the icons to each layer
    pathLayers.start.addIcon(icons.start);
    pathLayers.end.addIcon(icons.end);
    // Render the just added Icons
    jmapController.renderCurrentMapView();
  } else {
    error = 'No se encontró la ruta entre el origen y destino señalados';
  }

  return { error, path };
};

const getFloorsPages = (infoMall, floorsPerPage) => {
  if (infoMall && infoMall.buttonsFloors) {
    let pages = Math.floor(infoMall.buttonsFloors.length / floorsPerPage);

    if (infoMall.buttonsFloors.length % floorsPerPage !== 0) {
      pages += 1;
    }

    const arrPages = [];
    const arrFloors = [...infoMall.buttonsFloors.reverse()];

    for (let p = 0; p < pages; p++) {
      const arrAux = arrFloors.filter(
        (item, index) =>
          item && index >= p * floorsPerPage && index < p * floorsPerPage + 3
      );

      arrPages.push(arrAux.reverse());
    }

    infoMall.buttonsFloors.reverse();

    return arrPages;
  }

  return [];
};

export {
  highlight,
  getStore,
  changeFloor,
  colorDestinationStore,
  zoom,
  initControllerMap,
  getDeviceByName,
  getWaypointFromDestination,
  highlightAndZoom,
  highlightAndZoomFromAmenity,
  applyDeviceOrientation,
  initMapDevices,
  prepareMapForRender,
  getStoreFromAmenitie,
  getWaypointBySuc,
  getFloorsPages
};

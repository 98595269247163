import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const isDebug = (process.env.REACT_APP_I18N_DEBUG || '').trim() === '';

i18n.use(initReactI18next).init({
  resources: {
    es: {
      translations: {
        MALL_ID_NOT_FOUND: 'El código del mall no existe',
        EXTERNAL_ID_NOT_FOUND: 'No se encontró',
        FLOORS_NAME: 'Piso',
        REPEAT_ROUTE: 'Repetir Ruta',
        ACCESIBLE_ROUTE: 'Ruta accesible',
        UP_STAIRS_MECHANICAL: 'Sube las escaleras mecánicas',
        DOWN_STAIRS_MECHANICAL: 'Baja las escaleras mecánicas',
        UP_STAIRS: 'Sube las escaleras',
        DOWN_STAIRS: 'Baja las escaleras',
        UP_ELEVATOR: 'Sube en el ascensor',
        DOWN_ELEVATOR: 'Baja en el ascensor'
      }
    },
    en: {
      translations: {
        MALL_ID_NOT_FOUND: "The mall code doesn't exist",
        EXTERNAL_ID_NOT_FOUND: "We couldn't find the route's origin",
        FLOORS_NAME: 'Floor',
        REPEAT_ROUTE: 'Repeat route',
        ACCESIBLE_ROUTE: 'Accesible route',
        UP_STAIRS_MECHANICAL: 'Take the escalator up',
        DOWN_STAIRS_MECHANICAL: 'Take the escalator down',
        UP_STAIRS: 'Take the stairs up',
        DOWN_STAIRS: 'Take the stairs down',
        UP_ELEVATOR: 'Take the elevator up',
        DOWN_ELEVATOR: 'Take the elevator down'
      }
    },
    pt: {
      translations: {
        MALL_ID_NOT_FOUND: 'O código do shopping não existe',
        EXTERNAL_ID_NOT_FOUND: 'Não encontramos a origem do caminho',
        FLOORS_NAME: 'Andar',
        REPEAT_ROUTE: 'Repetir caminho',
        ACCESIBLE_ROUTE: 'Roa accesible',
        UP_STAIRS_MECHANICAL: 'Suba as escadas rolantes',
        DOWN_STAIRS_MECHANICAL: 'Desça as escadas rolantes',
        UP_STAIRS: 'Suba as escadas',
        DOWN_STAIRS: 'Desça as escadas',
        UP_ELEVATOR: 'Suba no elevador ',
        DOWN_ELEVATOR: ' Desça no elevador'
      }
    }
  },
  fallbackLng: 'es',
  debug: isDebug,
  ns: ['translations'],
  defaultNS: 'translations',
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
});

export default i18n;

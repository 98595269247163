import uuid from 'uuid/v4';

import { COOKIES_DISABLED, NO_NAME } from '../constants/messages';

export const getDevice = function(name) {
  if (!navigator.cookieEnabled) {
    return {
      totemId: COOKIES_DISABLED,
      totemName: COOKIES_DISABLED
    };
  }

  let totemId = localStorage.getItem('com.parauco.map.deviceId');
  let totemName = localStorage.getItem('com.parauco.map.deviceName');
  const mallId = localStorage.getItem('com.parauco.map.mallId');

  if (
    totemId === undefined ||
    totemId === '' ||
    totemId === null ||
    totemName === undefined ||
    totemName === '' ||
    totemName === null
  ) {
    totemId = `${uuid()}`;
    totemName = name || NO_NAME;

    localStorage.setItem('com.parauco.map.deviceId', totemId);
    localStorage.setItem('com.parauco.map.deviceName', totemName);
    localStorage.setItem('com.parauco.map.mallId', mallId);
  }

  return { totemId, totemName };
};

export const newSession = function() {
  if (!navigator.cookieEnabled) {
    return COOKIES_DISABLED;
  }

  const id = uuid();

  localStorage.setItem('com.parauco.map.sessionId', id);

  return id;
};

export const getSession = function() {
  if (!navigator.cookieEnabled) {
    return COOKIES_DISABLED;
  }

  const id = localStorage.getItem('com.parauco.map.sessionId') || '';

  if (id === '') {
    return newSession();
  }

  return id;
};

export const getMall = function() {
  if (!navigator.cookieEnabled) {
    return COOKIES_DISABLED;
  }

  const id =
    localStorage.getItem('com.parauco.map.mallId') ||
    process.env.REACT_APP_MALL_ID ||
    'default_mall';

  return id;
};

export const getTotemData = function(returnAsObject) {
  if (!navigator.cookieEnabled) {
    return COOKIES_DISABLED;
  }

  const device = getDevice();

  if (typeof returnAsObject === 'boolean' && returnAsObject) {
    return {
      mallId: getMall(),
      deviceName: device.totemName,
      deviceId: device.totemId,
      sessionId: getSession()
    };
  }

  return `mallId=${getMall()};deviceName=${device.totemName};
  deviceId=${device.totemId};sessionId=${getSession()}`;
};

export const getDeviceFeatures = (
  {
    DIVICE_OS_NAME,
    DIVICE_OS_VERSION,
    VENDOR,
    MODEL,
    DIVICE_WIDTH,
    DIVICE_HEIGHT
  },
  seconds
) => `SO = ${DIVICE_OS_NAME};
  Versión = ${DIVICE_OS_VERSION};
  Marca = ${VENDOR};
  Modelo = ${MODEL};
  Divice = ${DIVICE_WIDTH}-${DIVICE_HEIGHT};
  Tiempo = ${seconds} seg;`;

import axios from 'axios';

import Request from '../config/request';
import {
  DATA_STORES,
  POI_TYPE_ID_STORES,
  LIMIT_PAGE_STORES,
  POI_STATE_ID_STORES,
  ORDER_BY_NAME_ASC,
  URL_ENDPOINT_STORES
} from '../constants/request';
import { multiMallImportantData } from '../config/multiMallImportantData';

let cancelToken;

const getImportantData = mallCode => {
  if (mallCode) {
    return multiMallImportantData.filter(
      data => data.mallCode.toUpperCase() === mallCode.toUpperCase()
    );
  }

  return null;
};

export const getStoreList = async (name, skip, mallCode) => {
  if (typeof cancelToken !== typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.');
  }

  cancelToken = axios.CancelToken.source();

  const mallData = getImportantData(mallCode);

  if (mallData && mallData.length > 0) {
    const response = await Request.post(
      URL_ENDPOINT_STORES,
      {
        include: DATA_STORES,
        name: name || '',
        poiTypeIdIn: POI_TYPE_ID_STORES,
        limit: LIMIT_PAGE_STORES,
        skip,
        poiStateIdIn: POI_STATE_ID_STORES,
        orderBy: ORDER_BY_NAME_ASC
      },
      {
        cancelToken: cancelToken.token,
        headers: {
          'app-key': mallData[0].appKey,
          'a-mall-id': mallData[0].mallID
        }
      }
    );

    return response.data;
  }

  return null;
};

export const getMallImportantData = mallCode => {
  if (typeof cancelToken !== typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.');
  }

  cancelToken = axios.CancelToken.source();

  const response = getImportantData(mallCode);

  return response || null;
};

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

import Header from '../../components/Header';
import List from '../../components/List';
import ModalMap from '../../components/Modal';
import LocalStorage from '../../services/localStorage';
import { LANDING_TOTEM } from '../../constants/localStorage';
import { loadMapConfiguration } from '../../utils/malls';
import { JibestreamContext } from '../../context/JibestreamContext';
import { getMallImportantData } from '../../services/landing';
import { multiMallImportantData } from '../../config/multiMallImportantData';

import style from './style.module.css';

Landing.propTypes = {
  match: PropTypes.any.isRequired
};

function Landing(props) {
  const { match } = props;
  const { params } = match;
  const { origin, mallCode } = params;
  const mallData = getMallImportantData(mallCode);
  const [dataStore, setDataStore] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const { jibestreamContext, setJibestreamContext } = useContext(
    JibestreamContext
  );

  LocalStorage.setValue(LANDING_TOTEM, origin);
  document.title = 'Parque Arauco - Encuentra tu Tienda';

  const handleClickStore = data => {
    setDataStore(data);
    ReactGA.event({
      category: 'Landing - Lista de Tiendas',
      action: 'Click en tienda',
      label: `suc = ${data.suc};
             name = ${data.name};`
    });
    ReactGA4.event({
      category: 'Landing - Lista de Tiendas',
      action: 'Click en tienda',
      label: `suc = ${data.suc};
             name = ${data.name};`
    });
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (mallData && mallData.length > 0) {
      const initJibestream = async () => {
        const configMap = await loadMapConfiguration(mallData[0].mallCode);

        setJibestreamContext({
          ...jibestreamContext,
          configMap
        });
      };

      ReactGA.event({
        category: 'Landing - Lista de Tiendas',
        action: 'Ingreso a Landing',
        label: `Mall = ${mallData[0].mallCode};
              Origin = ${origin};`
      });
      ReactGA4.event({
        category: 'Landing - Lista de Tiendas',
        action: 'Ingreso a Landing',
        label: `Mall = ${mallData[0].mallCode};
              Origin = ${origin};`
      });

      initJibestream();
    }
  }, []);

  return (
    <div>
      <Header mallData={mallData && mallData.length > 0 ? mallData[0] : null} />
      <div className={style.content}>
        {mallData.length > 0 ? (
          <List handleClickStore={handleClickStore} mallData={mallData[0]} />
        ) : (
          <div>
            <div className='text-center'>
              No se encuentra mall con código: <strong>{mallCode}</strong>
            </div>
            <div style={{ marginLeft: 'calc(50% - 130px)', marginTop: '30px' }}>
              <div>Los códigos de los malls son:</div>
              <ul>
                {multiMallImportantData.map(mall => (
                  <li>
                    {mall.mallCode}: {mall.mallName}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      <ModalMap
        dataStore={dataStore}
        origin={origin}
        openModal={openModal}
        handleCloseModal={handleCloseModal}
      />
    </div>
  );
}

export default Landing;

const multiMallImportantData = [
  {
    mallID: 1,
    mallCode: 'PAK',
    mallName: 'Mall Parque Arauco',
    mallLogo: '../../assets/images/landing/logos/Logo_PAK.png',
    appKey: 'b675d91de86b49d6a0480566465fa87e',
    webUrl: 'https://www.parquearauco.cl/'
  },
  {
    mallID: 2,
    mallCode: 'MAM',
    mallName: 'Mll Arauco Maipú',
    mallLogo: '../../assets/images/landing/logos/Logo_MAM.png',
    appKey: '47417548418f42e5b773c695763bc8e8',
    webUrl: 'https://www.araucomaipu.cl/'
  },
  {
    mallID: 3,
    mallCode: 'BOM',
    mallName: 'Mall Arauco Premium Outlet Buenaventura',
    mallLogo: '../../assets/images/landing/logos/Logo_BOM.png',
    appKey: 'c958840f61a54e0a918120b687f41b86',
    webUrl: 'https://www.araucopremiumoutletbuenaventura.cl'
  },
  {
    mallID: 4,
    mallCode: 'ACH',
    mallName: 'Mall Arauco Chillán',
    mallLogo: '../../assets/images/landing/logos/Logo_ACH.png',
    appKey: '6e35a700dc7946c988308fba21b52f0c',
    webUrl: 'https://www.araucochillan.cl/'
  },
  {
    mallID: 5,
    mallCode: 'MAE',
    mallName: 'Mall Arauco Estación',
    mallLogo: '../../assets/images/landing/logos/Logo_MAE.png',
    appKey: '0bc9fd2895e546909a5743a0d69f3d20',
    webUrl: 'https://www.araucoestacion.cl/'
  }
];

export { multiMallImportantData };

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import queryString from 'query-string';

import api from '../../config/api';
import { getDevice, getSession } from '../../config/kiosk';
import ResultadoMapa from '../../containers/ResultadoMapa/Resultado';
import Landing from '../../containers/Landing/Landing';
import ResultadoMapaParking from '../../containers/ResultadoMapaParking/ResultadoParking';

const _ci = (process.env.CI || '').trim();

const qs = queryString.parse(window.location.search);

if (Object.prototype.hasOwnProperty.call(qs, 'kiosk')) {
  getDevice(qs.kiosk);
}

ReactGA.initialize(api.analyticsId, {
  testMode: _ci === 'true',
  gaOptions: {
    cookieFlags: 'secure;samesite=none',
    deviceName: getDevice().deviceName,
    deviceId: getDevice().deviceId,
    sessionId: getSession()
  }
});

ReactGA4.initialize([
  {
    trackingId: api.analytics4Id,
    gaOptions: {
      cookieFlags: 'secure;samesite=none',
      deviceName: getDevice().deviceName,
      deviceId: getDevice().deviceId,
      sessionId: getSession()
    },
    testMode: _ci === 'true'
  }
]);

class Main extends React.Component {
  render() {
    document.oncontextmenu = this.inhabilitar;

    return (
      <React.Fragment>
        <span alt='0.11.0' id='app-version' style={{ display: 'none' }} />
        <Switch>
          <Route
            path='/:mall/:language/no-selectable/:origin/:amenity'
            component={ResultadoMapa}
            exact
          />
          <Route
            path='/:mall/:language/no-selectable/:noShowPath/:origin/:amenity'
            component={ResultadoMapa}
            exact
          />
          <Route
            path='/static-map/:mall/:language'
            component={ResultadoMapa}
            exact
          />
          <Route
            path='/:mall/:languageCode/arma-tu-ruta/:origin'
            component={Landing}
            exact
          />
          <Route
            path='/parking-map/:mall/:language/:originMall/:destinationParking/:area'
            component={ResultadoMapaParking}
            exact
          />

          <Route
            path='/atm-route/:mall/:language/:origin/:noDestination/:type?'
            component={ResultadoMapa}
            exact
          />
          <Route
            path='/atm-route-direct/:mall/:language/:origin/:destinationFloor/:type?'
            component={ResultadoMapa}
            exact
          />
          <Route
            path='/:mall/:language/:origin/:destination/:type?'
            component={ResultadoMapa}
            exact
          />
          <Route
            path='/:mall/:language/:destination'
            component={ResultadoMapa}
            exact
          />
        </Switch>
      </React.Fragment>
    );
  }
}

export default Main;

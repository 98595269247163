import jmap from 'jmap.js';

import mapEnd from '../../assets/images/img_blank.png';
// import mapStart from '../../assets/images/map_start.png';
import mapStart from '../../assets/images/pin.svg';

export const ACTIVE_STYLE = new jmap.Style({
  fill: '#0251B7',
  stroke: '#0251B7',
  strokeWidth: 2,
  strokeOpacity: 1
});

export const IN_ACTIVE_STYLE = new jmap.Style({
  fill: '#EEEEEE',
  stroke: '#0251B7',
  strokeWidth: 2,
  strokeOpacity: 1
});

export const STYLE_PATH = {
  stroke: '#005ace',
  strokeOpacity: 0.8,
  strokeWidth: 2
};

export const STYLE_HIGH_LIGHT = {
  fill: '#555555',
  strokeWidth: 0
};

export const MAP_UI_KIT = {
  padding: [15],
  ACTIVE_STYLE,
  IN_ACTIVE_STYLE
};

export const STYLE_ICON_START = {
  url: mapStart,
  width: 30,
  height: 30,
  rotateWithMap: false,
  rotation: 0,
  name: 'start'
};

export const STYLE_ICON_END = {
  url: mapEnd,
  width: 50,
  height: 50,
  rotateWithMap: true,
  rotation: 0,
  name: 'end'
};

export const COLOR_SPINNER = {
  color: '#3E93CE'
};
